// Libs.
import React, {useState, useEffect, useContext} from 'react';
import { get } from 'lodash';
// Deps.
import CartSummary from './cart-summary';
import CartFMKSummary from './cart-fmk-summary';
// State.
import { useStateValue } from '../../store/state';
import { LoftItemsMapContext, LoftItemsMapInit } from '../checkout/context/loft-items-map-context';
// Hooks.
import useClassAvailability from '../../hooks/useClassAvailability';
// Assets.
import styles from './cart.module.scss';
import CartTotal from './cart-total';
import CartFmkTotal from './cart-fmk-total';
import PagePreloader from '../../components/common/loaders/page-preloader';
import {AppContext} from "../layout/AppLayout";
import { useCartClassSantizer } from '../../libs/class/cart-class-sanitizer';

export default () => {
  const [state] = useStateValue();

  // To be used later to display errors
  const { invalidCartItems} = useCartClassSantizer();

  const fmkLineitems = get(state, 'cart.fmk.lineItemsCount', 0);
  const loftLineitems = get(state, 'cart.loft.lineItemsCount', 0);
  const [isReady, setReady] = useState(false);
  const initialLoftItemsMap = LoftItemsMapInit();
  const [loftItemsMap, setLoftItemsMap] = useState(initialLoftItemsMap);
  const isApp = useContext(AppContext);

  useEffect(() => {
    setReady(true);
  }, []);

  // Update local state when cart items changed (removed) so items map context is refreshed
  // across components due is not propagating automatically.
  useEffect(() => {
    setLoftItemsMap(initialLoftItemsMap);
  }, [initialLoftItemsMap.cartItems.length]);

  const [availabilityHash, setAvailabilityHash] = useState();
  useClassAvailability(loftItemsMap.classSchedules, setAvailabilityHash, false, 0.15);

  return (
    <div className={styles.cartBackground}>
      <div className={styles.cartContainer} data-app={isApp}>
        <h1>My Cart</h1>
        {!isReady && <PagePreloader />}
        {isReady && (
          <>
            {!fmkLineitems && !loftLineitems ? (
              <div className={styles.cartSummaryFullContainer}>
                <div className={styles.cartSummaryContainer}>
                  <p>Your cart is empty</p>
                </div>
              </div>
            ) : (
              <>
                {fmkLineitems > 0 && (
                  <div className={styles.cartSummaryFullContainer}>
                    <CartFMKSummary variation="cart" />
                    <CartFmkTotal checkout={true} />
                  </div>
                )}
                {loftLineitems > 0 && (
                  <LoftItemsMapContext.Provider value={[loftItemsMap, setLoftItemsMap]}>
                    <div className={styles.cartSummaryFullContainer}>
                      <CartSummary availabilityReady={!!availabilityHash} />
                      <CartTotal availabilityReady={!!availabilityHash} />
                    </div>
                  </LoftItemsMapContext.Provider>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
