import React, {useContext, useEffect} from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { defaults, some } from 'lodash';
// Libs.
import { useCalculateLoftCart } from '../../libs/class/loft-cart-calculator';
import { checkDateChangeCartItems } from '../../libs/class/class-validator';
import { dateAndTimeWithTimezoneCorrection } from '../../libs/form/dates';
// Assets.
import styles from './cart-total.module.scss';
import pageAliases from '../../data/pageAliases';
import appPageAliases from '../../data/appAliases';
import locale from '../../locale/locale.json';
// State.
import LoftItemsMap from '../checkout/context/loft-items-map-context';
import { useStateValue } from '../../store/state';
import { UpdateItem } from '../../store/cart/action-creators';
// Loaders.
import PagePreloader from '../common/loaders/page-preloader';
import {AppContext} from "../layout/AppLayout";
import navigate from '../../libs/navigate';

const CartTotal = ({ availabilityReady }) => {
  const [, dispatch] = useStateValue();
  // Loft mapped cart items class schedules from context.
  const { classSchedules, cartItems } = LoftItemsMap();
  const { totals } = useCalculateLoftCart(cartItems);
  const isApp = useContext(AppContext)
  const dateChanges = checkDateChangeCartItems(classSchedules, cartItems);
  const someClassesAreCancelled = some(classSchedules, 'availability.isCancel');
  const someClassesAreClosed = some(classSchedules, 'availability.isClosed');
  const handleCartItemsValidation = (e) => {
    // Prevent continue to checkout if cart has cancelled items.
    if (someClassesAreCancelled || someClassesAreClosed) {
      e.preventDefault();
    }

    if (dateChanges) {
      e.preventDefault();
      // Update cart items with real availability date / time.
      cartItems.forEach((cartItem, index) => {
        const classSchedule = classSchedules[index];
        const { availability } = classSchedule;
        const description = `${dateAndTimeWithTimezoneCorrection(
          availability.startDate,
          availability.startTime
        ).format('MM/DD/YYYY')} ${availability.startTime} to ${availability.endTime}`;
        const updateItem = defaults(
          {
            description,
            startDate: availability.startDate,
            startTime: availability.startTime,
            endTime: availability.endTime,
          },
          cartItem
        );
        dispatch(UpdateItem(updateItem));
      });

      navigate(isApp ? appPageAliases.appCheckout : pageAliases.classesCheckout);
    }
  };

  return (
    <div className={styles.cartTotalContainer}>
      {!availabilityReady ? (
        <PagePreloader />
      ) : (
        <>
          <dl>
            <dt>Subtotal:</dt>
            <dd>${totals.subtotal.toFixed(2)}</dd>
            <dt>GST/HST:</dt>
            <dd>${totals.tax.toFixed(2)}</dd>
            <dt>Total:</dt>
            <dd>${totals.total.toFixed(2)}</dd>
          </dl>
          <Link
            onClick={handleCartItemsValidation}
            className={styles.cartTotalCheckout}
            to={isApp ? appPageAliases.appCheckout : pageAliases.classesCheckout}
            data-disabled={someClassesAreCancelled || someClassesAreClosed ? 'true' : 'false'}
          >
            {locale.checkoutPage.buttons.checkoutButton}
          </Link>
          {(dateChanges || someClassesAreCancelled || someClassesAreClosed) && (
            <p className={styles.cartTotalChangesWarning}>
              {locale.checkoutPage.warnings.reviewChanges}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default CartTotal;
