// Libs.
import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { isEmpty, keyBy, uniqueId } from 'lodash';
// Custom Libs.
import { minToMillsec } from '../../libs/timeTransform';
import { useCalculateLoftCart } from '../../libs/class/loft-cart-calculator';
// Deps.
import CartTotal from './cart-total';
import ClassLineItemEdit from '../common/commerce/class-line-item-edit';
// State.
import { useStateValue } from '../../store/state';
import { RemoveItem } from '../../store/cart/action-creators';
import LoftItemsMap from '../checkout/context/loft-items-map-context';
// Hooks.
import useClassLineItemRemove from '../../hooks/useClassLineItemRemove';
import { useClassesStaticQuery } from '../../hooks/useClassesStaticQuery';
// Assets.
import styles from './cart-summary.module.scss';
import summaryImage from '../../images/LOFT-CART-ICON.png';
// Loaders.
import PagePreloader from '../common/loaders/page-preloader';

const CartSummary = ({ showTotal = true, availabilityReady }) => {
  const [, dispatch] = useStateValue();
  const { remove } = useClassLineItemRemove(dispatch);
  const { classSchedules, cartItems } = LoftItemsMap();
  const { lineItems, lineItemsTotals } = useCalculateLoftCart(cartItems);
  const count = lineItems.length;

  // Handle empty cart.
  return (
    <div className={styles.cartSummaryContainer}>
      {!availabilityReady ? (
        <PagePreloader />
      ) : (
        <>
          {count > 0 && (
            <>
              <div className={styles.cartSummaryHeader}>
                <div className={styles.cartSummaryImage}>
                  <img src={summaryImage} alt="Cart Summary" />
                </div>
                <h2 className={styles.cartSummaryTitle}>Loft classes ({count})</h2>
                <div className={styles.cartSummaryTotal}>${lineItemsTotals.subtotal}</div>
              </div>
              {lineItems.map((item, index) => (
                <ClassLineItemEdit
                  key={uniqueId(`${item.id}_cart_summary_item`)}
                  item={item}
                  classSchedule={classSchedules[index]}
                  removeCallback={remove}
                />
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CartSummary;
