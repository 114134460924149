// Libs.
import React from 'react';
// Deps.
import Cart from '../components/cart/cart';
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';

const CartPage = () => {
  return (
    <Layout>
      <SEO title="Cart" />
      <Cart />
    </Layout>
  );
};

export default CartPage;
